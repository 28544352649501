import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import UserContext from '../src/UserContext';
import { useState } from 'react';
import { Routes, Route, Navigate, useLocation, Outlet, } from "react-router-dom";
import VerifyCode from './Components/VerifyCode/VerifyCode';


//Docker = process.env.REACT_APP_APIURL;
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;


axios.defaults.headers = {
  'Content-Type': 'application/json',
  'X-Content-Type-Options': 'nosniff',
  'Content-Security-Policy': "default-src 'self'",
  'X-Frame-Options': "deny",
  "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
  "withCredentials": true
};
function App() {
  const [userData, setUser] = useState([]);
  return (
    <div className="app-container">
      <UserContext.Provider value={{
        ...userData,
        setUser,
      }}>
        <Routes>
          <Route path="/launch" element={<VerifyCode />} />
          <Route path="*" element={<Navigate to="/launch" replace />} />
        </Routes>

      </UserContext.Provider>
    </div>
  );
}

export default App;
