import React, { useState } from "react"
import { Button } from 'react-bootstrap'
import OtpInput from "react-otp-input"
import "./VerifyCode.css";
import axios from 'axios';
import lockIcon from '../../Image/icon_lock_outline.png'

const VerifyCode = () => {
    const [otp, setOtp] = React.useState('');
    const [errorMessage, setErrorMessage] = useState("");

    const onChange = (value) => {
        setErrorMessage("");
        setOtp(value);
    };

    const handleSubmit = () => {
        let result = otp.toString().length;
        if (result === 6) {
            axios.get("/verificationCode/verify-code/" + otp)
                .then((res) => {
                    if (res.data.message === "invalid code") {
                        setErrorMessage("Code is incorrect. Please try again.");
                    } else {
                        setErrorMessage("");
                        let actor = JSON.stringify({
                            mbox: res.data.userId + res.data.organization
                        });
                        let CourseLaunchURL = "endpoint=" + process.env.REACT_APP_LRS_ENDPOINT + "/data/xAPI/statements" + "&auth=" + res.data.clientAuthToken + "&experience_id=" + res.data.experience + "&mode=Headset" + "&course_id=" + res.data.course + "&actor=" + actor

                        let encodeURI = encodeURIComponent(CourseLaunchURL);
                        let tempextrapara = ""
                        if (res.data.moduleLevel !== "" && res.data.moduleLevel !== undefined) {
                            tempextrapara = "&module=" + res.data?.moduleLevel
                        }

                        window.open(
                            process.env.REACT_APP_S3_FOLDER + res.data.launchURL + "?" + encodeURI + tempextrapara,
                            '_self'
                        );

                    }
                }).catch((error) => {
                    console.log(error);
                })
        } else {
            setErrorMessage("Please enter valid code");
        }


    }
    return <div className="code-container">
        <div className="code-body">
            <img className="mb-3" src={lockIcon} />
            <div className="code-header">Enter the code</div>
            <div className="code-heading">Enter the code provided on your headset launch page, then select <b>Submit.</b></div>
            <div className="otp-input">
                <OtpInput
                    value={otp}
                    onChange={onChange}
                    numInputs={6}
                    separator={<span className="me-2"> </span>}
                    isInputNum={true}
                />
            </div>
            {errorMessage !== "" && <div className="code-error-message">{errorMessage}</div>}
            <div className="d-flex justify-content-center">
                <Button className="custom-button" onClick={handleSubmit}>Submit</Button>
            </div>
        </div>

    </div >
}

export default VerifyCode
